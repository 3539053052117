import { useState, createRef } from 'react';
import { Autoplay, Pagination, EffectFade, Navigation } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/autoplay';
import 'swiper/css/pagination';
import 'swiper/css/effect-fade';
import 'swiper/css/navigation';
import './App.scss';
import ImageHeaderLogo from './assets/logo-header.png';
import ImageFooterLogo from './assets/logo-footer.png';
import ImageBlock1Background from './assets/block1-bg.png';
import ImageBlock2Image11 from './assets/block2-image11.png';
import ImageBlock2Image12 from './assets/block2-image12.png';
import ImageBlock2Image21 from './assets/block2-image21.png';
import ImageBlock2Image31 from './assets/block2-image31.png';
import ImageBlock2Image32 from './assets/block2-image32.png';
import ImageBlock2Image33 from './assets/block2-image33.png';
import ImageBlock2Image41 from './assets/block2-image41.png';
import ImageBlock2Image42 from './assets/block2-image42.png';
import ImageBlock2Image43 from './assets/block2-image43.png';
import ImageBlock2Image44 from './assets/block2-image44.png';
import ImageBlock2Image45 from './assets/block2-image45.png';
import ImageBlock2Image46 from './assets/block2-image46.png';
import ImageBlock2Image47 from './assets/block2-image47.png';
import ImageBlock2Image48 from './assets/block2-image48.png';
import ImageBlock2Image49 from './assets/block2-image49.png';
import ImageBlock3Image1 from './assets/block3-image1.png';
import ImageBlock3Image2 from './assets/block3-image2.png';
import ImageBlock3Image3 from './assets/block3-image3.png';
import ImageBlock3Image4 from './assets/block3-image4.png';
import ImageBlock4Icon1 from './assets/block4-icon1.png';
import ImageBlock4Icon2 from './assets/block4-icon2.png';
import ImageBlock4Icon3 from './assets/block4-icon3.png';
import ImageBlock4Icon4 from './assets/block4-icon4.png';
import ImageBlock4Icon5 from './assets/block4-icon5.png';
import ImageBlock4Icon6 from './assets/block4-icon6.png';
import ImageBlock4Icon7 from './assets/block4-icon7.png';
import ImageBlock5Icon1 from './assets/block5-icon1.png';
import ImageBlock5Icon2 from './assets/block5-icon2.png';
import ImageBlock5Icon3 from './assets/block5-icon3.png';
import ImageBlock6Image1 from './assets/block6-image1.png';
import ImageBlock6Image2 from './assets/block6-image2.png';
import ImageBlock6Image3 from './assets/block6-image3.png';
import ImageBlock6Image4 from './assets/block6-image4.png';
import ImageWatermark1 from './assets/bg1.png';
import ImageWatermark2 from './assets/bg2.png';
import ImageArrow from './assets/arrow.png';

const TAB = ['首页', '解决方案', '硬件产品', '消费者体验', '赋能乳企', '联系我们'];

const BLOCK1CONFIG = {
  title: '鲜日达',
  subtitle: '巴氏鲜奶宅配系统',
  desc: '助力送奶到户复兴',
  images: ImageBlock1Background
};

const BLOCK2CONFIG = {
  title: '解决方案',
  desc: ['鲜日达宅配系统通过将物联网技术、AI视觉识别和互联网等先进技术融合应用在鲜奶宅配场景，极大地改善了用户体验，', '并且帮助乳企从生产、征订、配送等经营的各个环节全面提升效率，改善经营'],
  swipe1: {
    title: '鲜日达宅配系统',
    tab: [
      { label: '智能奶柜', images: [ImageBlock2Image11, ImageBlock2Image12] },
      { label: '用户端小程序', images: [ImageBlock2Image21] },
      { label: '生产/配送管理端', images: [ImageBlock2Image31, ImageBlock2Image32, ImageBlock2Image33] },
    ]
  },
  swipe2: [
    {
      title: '重新定义鲜奶宅配生态圈',
      list: [
        { index: 0, label: '线上获客', desc: '小红书、美团点评、抖音快手、朋友圈', image: ImageBlock2Image41, type: '1', width: 790, height: 538 },
        { index: 1, label: '地推征订', desc: '促销摆台、社区服务、物业福利、朋友圈', image: ImageBlock2Image49, type: '1', width: 790, height: 588 },
        { index: 2, label: '小程序入口', desc: '资金流、信息流合一', image: ImageBlock2Image42, type: '2', width: 790, height: 639 },
        { index: 3, label: '工厂/总部', desc: '柔性生产、配送计划', image: ImageBlock2Image43, type: '1', width: 790, height: 538 },
        { index: 4, label: '配送车辆', desc: '自动导航  线路优化', image: ImageBlock2Image44, type: '1', width: 790, height: 538 },
        { index: 5, label: '智能取奶柜', desc: '全程冷链、随时可取', image: ImageBlock2Image45, type: '1', width: 723, height: 767 },
        { index: 6, label: '用户取奶', desc: '扫码开门、AI识别', image: ImageBlock2Image46, type: '1', width: 560, height: 736 },
        { index: 7, label: '私域流量', desc: '社群运营、精准营销', image: ImageBlock2Image47, type: '1', width: 560, height: 736 },
        { index: 8, label: '用户裂变、粘性复购', desc: '二次传播、形成闭环', image: ImageBlock2Image48, type: '1', width: 790, height: 538 }
      ]
    },
    // {
    //   title: '地推征订',
    //   list: [
    //     { index: 8, label: '促销摆台、社区服务、<br />物业福利、朋友圈', desc: '地推征订', image: ImageBlock2Image49, type: '1', width: 790, height: 588 }
    //   ]
    // }
  ]
};

const BLOCK3CONFIG = {
  title: '硬件产品',
  desc: ['噬未荚碾魔隘乐阁！件瘠争园款撤遇葬牢坑灌删寮染犹万嘛银艰老陈氢团肝锰藻趟新艺砷挤节冈俘司刚核企概友椎秧', '芦俯乞骂粹亩班伐替破恳内实商季枪振制；吼义涨辞翻暖坟旺供自悲伊在拧司掌描胀吭焕筹论徐床脂句封科耻发圈蔗魂适弱祁连毕'],
  list: [
    {
      title: '智能壁挂柜',
      info: [
        { label: '规格', value: '65*45*24cm' },
        { label: '容积', value: '20L（200ml瓶约14瓶）' },
        { label: '功率', value: '60W' },
        { label: '安装方式', value: '壁挂' },
        { label: '开门方式', value: '扫码、刷卡、刷脸（选配）' },
        { label: '投放场景', value: '小区住宅电梯厅' },
        { label: '识别方式', value: '动态视觉识别' },
      ],
      images: [{ src: ImageBlock3Image1, width: 460 }, { src: ImageBlock3Image2, width: 465, margin: 18 }]
    },
    {
      title: '立式智能奶柜',
      info: [
        { label: '规格', value: '69*71*211cm' },
        { label: '容积', value: '400L（200ml瓶约160瓶）' },
        { label: '功率', value: '200W' },
        { label: '安装方式', value: '立式' },
        { label: '开门方式', value: '扫码、刷卡、刷脸（选配）' },
        { label: '投放场景', value: '小区公共区域，人流主通道' },
        { label: '识别方式', value: '动态视觉识别' },
      ],
      images: [{ src: ImageBlock3Image3, width: 400 }, { src: ImageBlock3Image4, width: 475, margin: 50 }]
    }
  ]
};

const BLOCK4CONFIG = {
  title: '消费者体验',
  list: [
    {
      icon: ImageBlock4Icon1,
      desc: ['真正全程冷链', '品质有保证']
    },
    {
      icon: ImageBlock4Icon2,
      desc: ['扫码、刷卡、刷脸均可开门']
    },
    {
      icon: ImageBlock4Icon3,
      desc: ['绑定亲友账号', '可授权他人开门']
    },
    {
      icon: ImageBlock4Icon4,
      desc: ['牛奶送达自动通知', '超时未取推送提醒']
    },
    {
      icon: ImageBlock4Icon5,
      desc: ['自由调整配送计划']
    },
    {
      icon: ImageBlock4Icon6,
      desc: ['线上付款', '电子发票', '安全便捷']
    },
    {
      icon: ImageBlock4Icon7,
      desc: ['智能客服', '随时响应']
    }
  ]
};

const BLOCK5CONFIG = {
  title: '赋能乳企',
  list: [
    {
      title: '赋能征订',
      icon: ImageBlock5Icon1,
      desc: ['多渠道传播，线上线下结合', '私域流量，社群运营，用户裂变', '数字化对接、多样营销工具，精准营销']
    },
    {
      title: '赋能管理',
      icon: ImageBlock5Icon2,
      desc: ['资金流、信息流、商品流实时同步', '品牌直达用户，营销直抵终端', '更多人机互动，高度流程化标准化', '追踪数据，行为溯源，精进管理']
    },
    {
      title: '赋能经营',
      icon: ImageBlock5Icon3,
      desc: ['用户数据沉淀，更高销售转化，<br />营销投放更高效', '私域运营，粘性强，易复购，客户价值最大化', '工厂直达终端，去周转仓，去二次物流，<br />效率提升5倍']
    }
  ]
};

const BLOCK6CONFIG = {
  title: '关于我们',
  images: [ImageBlock6Image1, ImageBlock6Image2, ImageBlock6Image3, ImageBlock6Image4],
  desc: ['&emsp;&emsp;武汉鲜日达科技有限公司，作为一家领先的巴氏奶宅配服务提供商，致力于通过创新技术和模式创新，持续优化消费者鲜奶消费体验，为顾客提供便捷、安全、可靠的鲜奶配送服务，同时为乳企提供高效的运营解决方案，助力乳企可持续发展。', '&emsp;&emsp;我们的解决方案已在武汉市试运行近两年，累计为近五千用户提供一百余万次的鲜奶宅配服务。']
};

const scrollContainer = createRef();
const eleHeader = createRef();
const eleBlock1 = createRef();
const eleBlock2 = createRef();
const eleBlock3 = createRef();
const eleBlock4 = createRef();
const eleBlock5 = createRef();
const eleBlock6 = createRef();
const blockElements = [eleBlock1, eleBlock2, eleBlock3, eleBlock4, eleBlock5, eleBlock6];

let BLOCK2SWIPE2COUNT = 0;
for (let index = 0; index < BLOCK2CONFIG.swipe2.length; index++) {
  const element = BLOCK2CONFIG.swipe2[index];
  BLOCK2SWIPE2COUNT += element.list.length;
}

const Arc = (props = {}) => {
  const { color = '#fff' } = props;
  return (
    <div className='arc-wrapper'>
      <div className='arc-top'>
        <div className='top-content' style={{ backgroundColor: color }}></div>
      </div>
      <div className='arc-bottom' style={{ backgroundColor: color }}></div>
    </div>
  )
}

function App() {
  const [activeTab, setActiveTab] = useState(0);
  const [isChangingTab, setIsChangingTab] = useState(false);
  const [activeSwipe1, setActiveSwipe1] = useState(0);
  const [activeSwipe2, setActiveSwipe2] = useState([0, 0, 0]);

  const scrollSmoothTo = (position) => {
    return new Promise(resolve => {
      if (!window.requestAnimationFrame) {
        window.requestAnimationFrame = function (callback) {
          return setTimeout(callback, 17);
        }
      }
      // 当前滚动高度
      let scrollTop = scrollContainer.current?.scrollTop || 0;
      // 滚动step方法
      let step = function() {
        // 距离目标滚动距离
        let distance = position - scrollTop;
        // 目标滚动位置
        scrollTop = scrollTop + distance / 5
        if (Math.abs(distance) < 1) {
          scrollContainer.current?.scrollTo(0, position);
          resolve(true);
        } else {
          scrollContainer.current?.scrollTo(0, scrollTop);
          requestAnimationFrame(step);
        }
      }
      step();
    });
  }

  const handleScroll = () => {
    if (isChangingTab) return;
    const scrollTop = scrollContainer.current.scrollTop;
    const headerHeight = eleHeader.current.offsetHeight;
    let active = 0;
    for (let index = 0; index < blockElements.length; index++) {
      const element = blockElements[index];
      if (scrollTop + headerHeight >= element.current.offsetTop) {
        active = index;
      }
    }
    if (active !== activeTab) {
      setActiveTab(active);
    }
  }

  const handleChangeTab = async (index) => {
    setIsChangingTab(true);
    setActiveTab(index);
    await scrollSmoothTo(blockElements[index].current.offsetTop - eleHeader.current.offsetHeight);
    setIsChangingTab(false);
  }

  const handlePre = () => {
    const { swipe2 } = BLOCK2CONFIG;
    let idx1 = activeSwipe2[0], idx2 = activeSwipe2[1], idx3 = activeSwipe2[2];
    if (idx1 === 0) {
      idx1 = BLOCK2SWIPE2COUNT - 1;
      idx2 = swipe2.length - 1;
      idx3 = 0;
    } else if (idx3 === 0) {
      idx1--;
      idx2--;
      idx3 = swipe2[idx2].list.length - 1;
    } else {
      idx1--;
      idx3--;
    }
    setActiveSwipe2([idx1, idx2, idx3]);
  }

  const handleNext = () => {
    const { swipe2 } = BLOCK2CONFIG;
    let idx1 = activeSwipe2[0], idx2 = activeSwipe2[1], idx3 = activeSwipe2[2];
    if (idx1 === BLOCK2SWIPE2COUNT - 1) {
      idx1 = 0;
      idx2 = 0;
      idx3 = 0;
    } else if (idx3 === swipe2[idx2].list.length - 1) {
      idx1++;
      idx2++;
      idx3 = 0;
    } else {
      idx1++;
      idx3++;
    }
    setActiveSwipe2([idx1, idx2, idx3]);
  }

  return (
    <div className="app-wrapper" ref={scrollContainer} onScrollCapture={handleScroll}>
      <div className='app-header' ref={eleHeader}>
        <div className='header-content'>
          <div className='header-logo' style={{ backgroundImage: `url(${ImageHeaderLogo})` }}></div>
          <div className='header-tab'>
            {
              TAB.map((item, index) => {
                return (
                  <div className={`tab-item ${activeTab === index ? 'active' : ''}`} key={index} onClick={handleChangeTab.bind(this, index)}>{item}</div>
                )
              })
            }
          </div>
        </div>
      </div>
      <div className='app-block app-block1' ref={eleBlock1} style={{ backgroundImage: `url(${BLOCK1CONFIG.images})` }}>
        <div className='block-content'>
          <div className='block-title'>{BLOCK1CONFIG.title}</div>
          <div className='block-subtitle'>{BLOCK1CONFIG.subtitle}</div>
          <div className='block-description'>{BLOCK1CONFIG.desc}</div>
        </div>
      </div>
      <div className='app-block app-block2' ref={eleBlock2}>
        <img className='block-watermark' src={ImageWatermark1} alt='' />
        <div className='block-title'>{BLOCK2CONFIG.title}</div>
        <div className='block-desc'>
          {
            BLOCK2CONFIG.desc.map((item, index) => {
              return (
                <div className='desc-text' key={index}>{item}</div>
              )
            })
          }
        </div>
        <div className='block-swipe1'>
          <Arc color='#EFEEEB' />
          <div className='swipe1-title'>{BLOCK2CONFIG.swipe1.title}</div>
          <div className='swipe1-tab'>
            {
              BLOCK2CONFIG.swipe1.tab.map((item, index) => {
                return (
                  <div
                    className={`tab-item ${activeSwipe1 === index ? 'active' : ''}`}
                    key={index}
                    onClick={() => setActiveSwipe1(index)}
                  >
                    <div className='item-text'>{item.label}</div>
                  </div>
                )
              })
            }
          </div>
          <div className='swipe1-images'>
            <Swiper
              modules={[Autoplay, Pagination, EffectFade, Navigation]}
              style={{ height: '100%' }}
              loop
              autoplay={{ disableOnInteraction: false }}
              pagination
              navigation
              spaceBetween={0}
              effect='fade'
            >
              {
                BLOCK2CONFIG.swipe1.tab[activeSwipe1].images.map((item, index) => {
                  return (
                    <SwiperSlide key={index}>
                      <img style={{ width: '100%' }} src={item} alt=''></img>
                    </SwiperSlide>
                  )
                })
              }
            </Swiper>
          </div>
        </div>
        <div className='block-swipe2'>
          <div className='swipe2-content'>
            <div className='content-left'>
              {
                BLOCK2CONFIG.swipe2.map((item, index) => {
                  return (
                    <div key={index}>
                      <div className='left-title'>{item.title}</div>
                      {
                        item.list.map((data, i) => {
                          return (
                            <div
                              className={`left-text ${activeSwipe2[0] === data.index ? 'active' : ''}`}
                              key={i}
                              onClick={() => setActiveSwipe2([data.index, index, i])}
                            >
                              <span>0{data.index + 1}</span>
                              <div dangerouslySetInnerHTML={{ __html: data.label }}></div>
                            </div>
                          )
                        })
                      }
                    </div>
                  )
                })
              }
            </div>
            <div className={`content-right ${BLOCK2CONFIG.swipe2[activeSwipe2[1]].list[activeSwipe2[2]].type === '2' ? 'padding' : ''}`}>
              <div
                className='right-image'
                style={{
                  width: `${BLOCK2CONFIG.swipe2[activeSwipe2[1]].list[activeSwipe2[2]].width}px`,
                  height: `${BLOCK2CONFIG.swipe2[activeSwipe2[1]].list[activeSwipe2[2]].height}px`,
                  backgroundImage: `url(${BLOCK2CONFIG.swipe2[activeSwipe2[1]].list[activeSwipe2[2]].image})`
                }}
              ></div>
            </div>
            <div className='content-active'>
              <div className='active-title'>
                <div className='title-content'>
                  <span>{BLOCK2CONFIG.swipe2[activeSwipe2[1]].list[activeSwipe2[2]].label.replace(/<br\s+\/>/g, '')}</span>
                </div>
              </div>
              <div className='active-desc'>{BLOCK2CONFIG.swipe2[activeSwipe2[1]].list[activeSwipe2[2]].desc}</div>
            </div>
          </div>
          <div className='swipe2-footer'>
            <div className='footer-dots'>
              {
                [...new Array(BLOCK2SWIPE2COUNT).keys()].map(item => {
                  return (
                    <div
                      className={`dots-item ${(activeSwipe2[0] === item) ? 'active' : ''}`}
                      key={item}
                    ></div>
                  )
                })
              }
            </div>
            <div className='footer-switch'>
              <div className='switch-btn' onClick={handlePre}>
                <div className='btn-icon' style={{ backgroundImage: `url(${ImageArrow})` }}></div>
              </div>
              <div className='switch-btn' onClick={handleNext}>
                <div className='btn-icon' style={{ backgroundImage: `url(${ImageArrow})` }}></div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='app-block app-block3' ref={eleBlock3}>
        <img className='block-watermark' src={ImageWatermark2} alt='' />
        <div className='block-content'>
          <div className='block-title'>硬件产品</div>
          {/* <div className='block-desc'>
            {
              BLOCK3CONFIG.desc.map((item, index) => {
                return (
                  <div className='desc-text' key={index}>{item}</div>
                )
              })
            }
          </div> */}
          <div className='block-list'>
            {
              BLOCK3CONFIG.list.map((item, index) => {
                return (
                  <div className='list-item' key={index}>
                    <div className='item-images'>
                      {
                        item.images.map((img, i) => {
                          return <img src={img.src} key={i} style={{ width: `${img.width}px`, marginLeft: `${img.margin || 0}px` }} alt='' />
                        })
                      }
                    </div>
                    <div className='item-info'>
                      <div className='info-title'>
                        <div className='title-content'><span>{item.title}</span></div>
                      </div>
                      {
                        item.info.map((info, i) => {
                          return (
                            <div className='info-text' key={i}><span>{info.label}</span>：{info.value}</div>
                          )
                        })
                      }
                    </div>
                  </div>
                )
              })
            }
          </div>
        </div>
      </div>
      <div className='app-block app-block4' ref={eleBlock4}>
        <div className='block-content'>
          <div className='block-title'>{BLOCK4CONFIG.title}</div>
          <div className='block-info'>
            {
              BLOCK4CONFIG.list.map((item, index) => {
                return (
                  <div className='info-item box' key={index}>
                    <div className='box-content'>
                      <div className='item-icon' style={{ backgroundImage: `url(${item.icon})` }}></div>
                      <div className='item-desc'>
                        {
                          item.desc.map((text, i) => {
                            return (
                              <div className='desc-text' key={i}>{text}</div>
                            )
                          })
                        }
                      </div>
                    </div>
                  </div>
                )
              })
            }
            <div className='info-item box'>
              <div className='box-content'>
                <div className='item-icon'></div>
                <div className='item-desc'>
                  <div className='item-dot'>
                    <span></span>
                    <span></span>
                    <span></span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='app-block app-block5' ref={eleBlock5}>
        <Arc color='#F5D130' />
        <div className='block-content'>
          <div className='block-title'>{BLOCK5CONFIG.title}</div>
          <div className='block-info'>
            {
              BLOCK5CONFIG.list.map((item, index) => {
                return (
                  <div className='info-item box' key={index}>
                    <div className='box-content'>
                      <div className='item-title'>
                        <div className='item-icon' style={{ backgroundImage: `url(${item.icon})` }}></div>
                        <span>{item.title}</span>
                      </div>
                      <div className='item-desc'>
                        {
                          item.desc.map((text, i) => {
                            return (
                              <div className='desc-text' key={i} dangerouslySetInnerHTML={{ __html: text }}></div>
                            )
                          })
                        }
                      </div>
                    </div>
                  </div>
                )
              })
            }
          </div>
        </div>
      </div>
      <div className='app-block app-block6' ref={eleBlock6}>
        <div className='block-content'>
          <div className='block-title'>{BLOCK6CONFIG.title}</div>
          <div className='block-image'>
            {
              BLOCK6CONFIG.images.map((item, index) => {
                return (
                  <div className='image-item box' style={{ backgroundImage: `url(${item})` }} key={index}></div>
                )
              })
            }
          </div>
          {
            BLOCK6CONFIG.desc.map((item, index) => {
              return (
                <div className='block-desc' key={index} dangerouslySetInnerHTML={{ __html: item }}></div>
              )
            })
          }
        </div>
      </div>
      <div className='app-footer'>
        <div className='footer-top'>
          <div className='footer-content'>
            <div className='footer-logo' style={{ backgroundImage: `url(${ImageFooterLogo})` }}></div>
            <div className='footer-info'>
              <div className='info-title'>联系我们</div>
              <div className='info-item'>电话：027-87896958 / 18607128880</div>
              <div className='info-item'>地址：武汉市洪山区高新大道776号华翔中心A2栋12B07</div>
            </div>
          </div>
        </div>
        <div className='footer-text'>
          <span>Copyright © 2024.鲜日达 All rights reserved.</span>
          <a href="https://beian.miit.gov.cn/" target="_blank" rel="noopener noreferrer">鄂ICP备2021020316号-1</a>
        </div>
      </div>
    </div>
  );
}

export default App;
